<template>
    <HeadlessDisclosure as="nav" class="bg-[#1A1D2B] dark:bg-[#17191c] py-4" v-slot="{ open }">
        <div class="max-w-7xl mx-auto px-6 lg:px-8">
            <div class="flex justify-between">
                <div class="flex gap-x-3 items-center">
                    <NuxtImg :src="image" class="h-7 rounded-md" />
                    <span class="text-white text-sm font-semibold">{{ name }}</span>
                </div>
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <div class="hidden lg:block">
                            <div id="mainMenu" class="flex space-x-2">
                                <NuxtLink v-for="link in links" :key="link.title" :to="link.to">
                                    {{ link.title }}
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                    <div class="flex lg:hidden">
                        <HeadlessDisclosureButton class="relative inline-flex items-center justify-center rounded-md text-white hover:text-primary focus:outline-none">
                            <span class="absolute -inset-0.5" />
                            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                        </HeadlessDisclosureButton>
                    </div>
                </div>
            </div>
        </div>
        <HeadlessDisclosurePanel class="mt-2 block lg:hidden py-2 px-6">
            <div id="subMenu" class="space-y-4">
                <NuxtLink class="w-full block text-sm font-semibold text-white hover:text-primary" v-for="link in links" :key="link.title" :to="link.to">
                    <HeadlessDisclosureButton class="w-full text-left block">
                        {{ link.title }}
                    </HeadlessDisclosureButton>
                </NuxtLink>
            </div>
        </HeadlessDisclosurePanel>
    </HeadlessDisclosure>
</template>

<script setup>
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    image: {
        type: String,
        required: true
    },
    links: {
        type: Array,
        required: true,
        default: () => ([])
    }
});
</script>

<style scoped>
#mainMenu > a {
    @apply text-white rounded-md hover:bg-primary hover:text-white px-3 py-2 text-sm font-semibold;
}

#mainMenu > a.router-link-active {
    @apply bg-primary text-white;
}

#subMenu > a.router-link-active {
    @apply text-primary;
}
</style>